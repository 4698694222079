
import Vue from "vue";

interface ComponentData {
  cssClass: string;
  internalFiles: UploadableFile[] | FileList;
}

interface UploadableFile extends File {
  fileId?: number;
}

export default Vue.extend({
  name: "DropUpload",
  inheritAttrs: false,
  props: {
    value: {
      type: [Array, FileList] as unknown as () => File[] | FileList,
      default: () => [],
    },
  },
  data: (): ComponentData => ({
    cssClass: "drag-inactive",
    internalFiles: [],
  }),
  watch: {
    value: {
      handler(): void {
        this.internalFiles = this.value;
      },
      deep: true,
    },
  },
  methods: {
    handleDrag(e: DragEvent): void {
      if (!e.dataTransfer) {
        return;
      }
      e.dataTransfer.dropEffect = "copy";
    },
    handleDrop(e: DragEvent): void {
      this.cssClass = "drag-inactive";
      if (!e.dataTransfer) {
        return;
      }
      e.dataTransfer.dropEffect = "copy";
      let files = e.dataTransfer.files;
      this.handleFiles(files);
    },

    handleHtmlFileInput(e: Event): void {
      if (!e.target) {
        return;
      }
      let files = (e.target as HTMLInputElement).files;
      if (!files) {
        return;
      }
      this.handleFiles(files);
    },
    handleFiles(files: File[] | FileList): void {
      if (this.$attrs.multiple) {
        (this.internalFiles as UploadableFile[]).push(...files);
        this.$emit("input", this.internalFiles);
        return;
      }
      this.internalFiles = files;
      this.$emit("input", this.internalFiles);
    },
  },
});
