import { post } from "@/application/api";
import { AxiosResponse } from "axios";

export const bulkSend = ({
  clientId,
  ids,
}: {
  clientId: number;
  ids: number[];
}): Promise<
  AxiosResponse<{
    data: {
      succeeded: number[];
      failed: number[];
    };
  }>
> => post(`client/${clientId}/product/bulk-send`, { ids });
