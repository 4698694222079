import { get, post } from "@/application/api";
import { AxiosResponse } from "axios";

export const exportproducts = (
  clientId: number
): Promise<AxiosResponse<Blob>> =>
  get(`client/${clientId}/product/concept/export`, { responseType: "blob" });

export const importproducts = (
  clientId: number,
  file: File
): Promise<AxiosResponse<{ data: { failed: number[] } }>> => {
  const formData = new FormData();
  formData.append("file", file);
  return post(`client/${clientId}/product/import`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
