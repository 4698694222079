import {
  getPaginated,
  PaginatedRequest,
  PaginatedResponse,
} from "@/application/api/getPaginated";
import { AxiosResponse } from "axios";
import mapIndexResponseErrors from "@/application/util/mapIndexResponseErrors";

export interface ProductIndexItem {
  id: number;
  clientName: string;
  clientId: number;
  orderDate?: string;
  order_number?: string;
  company_name?: string;
  full_name?: string;
  city?: string;
  country?: string;
  originalSource: string;
  errors?: { message: string; errors: Record<string, string[]> };
}

export const clientConceptProductIndex = async (
  data: PaginatedRequest,
  clientId: number
): Promise<AxiosResponse<PaginatedResponse<ProductIndexItem[]>>> => {
  const response = await getPaginated(`client/${clientId}/product/concept`, data);

  response.data.data = mapIndexResponseErrors(response.data.data);

  return response;
};

export const conceptProductIndex = async (
  data: PaginatedRequest
): Promise<AxiosResponse<PaginatedResponse<ProductIndexItem[]>>> => {
  const response = await getPaginated("product/concept", data);

  response.data.data = mapIndexResponseErrors(response.data.data);

  return response;
};
