
import Vue from "vue";

export default Vue.extend({
  name: "BulkDeleteButton",
  props: {
    selected: {
      type: Array,
      required: true,
    },
    deleteRequest: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
  }),
  methods: {
    async handleBulkDelete() {
      this.isLoading = true;
      await Promise.all(this.selected.map((item) => this.deleteRequest(item)));
      this.isLoading = false;
      this.$emit("deleted");
    },
  },
});
